import React from 'react'
import { graphql, Link } from 'gatsby'
import HeaderHome from '../components/HeaderHome'
import Layout from '../layouts'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

function HomePage({ location, data }) {
  const responsive = {
    0: {
      items: 1,
    },
    1024: {
      items: 5,
    },
  }
  React.useEffect(() => {
    Aos.init({ duration: 700 })
  }, [])
  const {
    header,
    section1,
    section2,
    section3,
    section4,
  } = data.markdownRemark.frontmatter
  return (
    <Layout location={location}>
      <div className="animPage">
        <HeaderHome title={<span>{header.title}</span>} />
        <section id="one" className="main style1 special">
          <header className="major ">
            <h2>{section1.title}</h2>
          </header>

          <div className="grid-wrapper">
            <div className="col-8">
              <div
                data-aos="fade-right"
                style={{ lineHeight: '1.35', textAlign: 'left' }}
              >
                <p>{section1.paragraph}</p>
                <p>{section1.paragraph1}</p>
                <p>{section1.paragraph2}</p>
                <p>{section1.paragraph3}</p>
                <p>{section1.paragraph4}</p>
                <p>{section1.paragraph5}</p>
              </div>
            </div>
            <div className="col-4">
              <img
                data-aos="fade-left"
                className="photo"
                src={section1.photo}
                alt=""
              />
            </div>
          </div>
        </section>
        <section id="two" className="main style2">
          <div className="grid-wrapper">
            <div className="col-5">
              <ul>
                <li>
                  <img
                    data-aos="fade-right"
                    className="photoInter"
                    src="../img/ginger-2.jpg"
                    alt=""
                  />
                </li>
                <li>
                  <img
                    data-aos="fade-right"
                    className="photoInter"
                    src="../img/pap-1.jpg"
                    alt=""
                  />
                </li>
                <li>
                  <img
                    data-aos="fade-right"
                    className="photoInter"
                    src="../img/cds-1.jpg"
                    alt=""
                  />
                </li>
              </ul>
            </div>
            <div className="col-7">
              <header className="major">
                <h2>{section2.title}</h2>
              </header>
              <div style={{ color: 'rgb(225, 225, 225)' }}>
                <p data-aos="fade-left" className="before">
                  {section2.paragraph1}
                </p>
                <p data-aos="fade-left" className="before">
                  {section2.paragraph2}
                </p>
                <p data-aos="fade-left" className="before">
                  {section2.paragraph3}
                </p>
                <p data-aos="fade-left" className="before">
                  {section2.paragraph4}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="three" className="main style1 special">
          <div className="col-12">
            <header className="major">
              <h2>{section3.title}</h2>
            </header>
            <p>{section3.paragraph}</p>
          </div>
          <div className="block">
            {section3.services.map(({ icon, title, paragraph }, i) => (
              <div data-aos="fade-up" key={i} className="block-item">
                <span className="image fit">
                  <img src={icon} alt="" />
                </span>
                <h3>{title}</h3>
                <p>{paragraph}</p>
              </div>
            ))}
          </div>
        </section>

        <section id="four" className="main style1 special">
          <header className="major">
            <h2>Ils nous font confiance</h2>
          </header>
          <div className="container-slider">
            <AliceCarousel
              dotsDisabled={true}
              buttonsDisabled={true}
              autoPlay={true}
              autoPlayInterval={1000}
              responsive={responsive}
            >
              <img className="item-logo" src="/img/logo_leclerc.png" alt="" />

              <img className="item-logo" src="/img/OEUF_DU_BREIL.png" alt="" />

              <img className="item-logo" src="/img/logo_legrand.png" alt="" />

              <img className="item-logo" src="/img/logo_pro-a-pro.png" alt="" />

              <img
                className="item-logo"
                src="/img/logo_forafrance.png"
                alt=""
              />
              <img className="item-logo" src="/img/citro.png" alt="" />

              <img className="item-logo" src="/img/ds.png" alt="" />

              <img className="item-logo" src="/img/ford.png" alt="" />

              <img className="item-logo" src="/img/r_armo.png" alt="" />

              <img className="item-logo" src="/img/ginger.png" alt="" />

              <img className="item-logo" src="/img/ald.png" alt="" />

              <img className="item-logo" src="/img/ciblex.png" alt="" />

              <img className="item-logo" src="/img/colissimo.png" alt="" />

              <img className="item-logo" src="/img/axians.png" alt="" />

              <img className="item-logo" src="/img/presco.png" alt="" />

              <img className="item-logo" src="/img/TCB.jpg" alt="" />
            </AliceCarousel>
          </div>
        </section>

        <section id="five" className="main style2 backgroundBottom special">
          <div className="container">
            <header className="major">
              <h2 style={{ textTransform: 'capitalize' }}>{section4.title}</h2>
            </header>
            <ul className="actions uniform">
              <li>
                <Link to={section4.link} className="button special">
                  {section4.linkText}
                </Link>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default HomePage

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        header {
          title
        }
        section1 {
          title
          paragraph
          paragraph1
          paragraph2
          paragraph3
          paragraph4
          paragraph5
          photo
        }
        section2 {
          title
          paragraph1
          paragraph2
          paragraph3
          paragraph4
        }
        section3 {
          title
          paragraph
          services {
            icon
            title
            paragraph
          }
        }
        section4 {
          title
          link
          linkText
        }
      }
    }
  }
`
