import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import logo from '../assets/images/logo.jpg'

function Header({ title }) {
  return (
    <section id="header" className="headerHome">
      <div className="inner">
        <Link to="/">
          <img className="logoMain" src={logo} width={200} alt="logo" />
        </Link>
        <h1 className="title">{title}</h1>
        <p className="animSubTitle">
          Votre partenaire de la conception à la livraison de votre projet
          <br />{" "}
          de construction et de rénovation.
        </p>
      </div>
    </section>
  )
}

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  scrollToContent: PropTypes.func,
  buttonContent: PropTypes.string,
}

export default Header
